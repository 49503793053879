import { padding } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import NewClosureForm from "../../Components/NewClosureForm/NewClosureForm";
import { useFetch } from "../../Hooks/useFetch";
import { getClosures } from "../../Services/ClosureService/ClosureService";
import { compareDate, compareDateWithHour } from "../../Utils/dateUtils";
import { useSelector } from "react-redux";
const columns = [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "FechaCierre",
    headerName: "Mes cierre",
    sortable: false,
    width: 200,
  },
  {
    field: "Fecha Ejecucion",
    headerName: "Fecha ejecución planificada",
    width: 300,
    sortComparator: compareDate,

    valueGetter: (params: any) => {
      return `${moment(params.value).format("DD-MM-YYYY")}`;
    },
  },
  {
    field: "copia_prinex",
    headerName: "Hora copia",
    width: 200,
  },
  {
    field: "Departamento",
    headerName: "Aplicación",
    sortable: false,
    width: 200,
    valueGetter: (params: any) => {
      if (params.value === "CG") {
        return `Control de Gestión`;
      }
      return `${params.value}`;
    },
  },
  {
    field: "Tipo",
    headerName: "Módulo",
    sortable: false,
    width: 200,
  },
  {
    field: "FechaModificado",
    headerName: "Fecha creación/modificación",
    sortComparator: compareDateWithHour,

    width: 300,
    valueGetter: (params: any) => {
      return `${moment(params.value).format("DD-MM-YYYY HH:mm")}`;
    },
  },
  {
    field: "creador",
    headerName: "Creador",
    sortable: false,
    width: 200,
  },
  {
    field: "Modificado por",
    headerName: "Modificado",
    sortable: false,
    width: 200,
    valueGetter: (params: any) => {
      if (params.value === null) {
        return "-";
      } else {
        return params.value;
      }
    },
  },

  {
    field: "Sobrescrito",
    headerName: "Se ha modificado",
    sortable: false,
    width: 160,
    valueGetter: (params: any) => {
      if (params.row["Modificado por"] === null) {
        return "No";
      } else {
        return "Si";
      }
    },
  },
];

function UpcomingClosures(props: any) {
  const user = useSelector((state: any) => state.userReducer.user?.user);
  const [closures, isFetchingClousures] = useFetch(getClosures, user?.email);
  const [Row, setRow] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (Row) {
      setOpenModal(true);
    }
  }, [Row]);

  useEffect(() => {
    if (!openModal) {
      setRow(null);
    }
  }, [openModal]);

  if (isFetchingClousures) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <CustomModal
        size="lg"
        show={openModal}
        onChange={() => {
          setOpenModal(!openModal);
        }}
        title="Editar cierre"
        body={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NewClosureForm
              setOpen={setOpenModal}
              edit={true}
              data={{
                month: Row?.FechaCierre,
                module: { id: 0, label: Row?.Departamento },
                type: {
                  Módulo: Row?.Departamento,
                  "Tipo de cierre": Row?.Tipo,
                },
                copiaPrinex: {
                  id: 0,
                  label: Row
                    ? moment(Row["Fecha Ejecucion"]).format("DD-MM-YYYY") +
                      " " +
                      Row?.copia_prinex
                    : "",
                },
                copia_date: Row ? Row["Fecha Ejecucion"] : "",
                ejecucion_date: Row?.copia_prinex,
              }}
            ></NewClosureForm>
          </div>
        }
      ></CustomModal>
      <div
        style={{
          height: "100vh",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontFamily: "lis-title", fontWeight: "bold" }}>
          Próximos cierres planificados
        </h1>
        <div
          style={{
            height: "80%",
            width: "95%",
            padding: "1rem",
            background: "white",
            borderRadius: "10px",
          }}
        >
          <DataGrid
            style={{ height: "100%" }}
            rows={
              closures && closures?.length > 0 && closures[0]?.fechas
                ? closures[0]?.fechas.map((value: any, i: number) => {
                    return { ...value, id: i };
                  })
                : []
            }
            onCellDoubleClick={(params, event) => {
              setRow(params.row);
            }}
            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      </div>
    </>
  );
}

export default UpcomingClosures;
