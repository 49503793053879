import React, { useEffect, useState } from "react";

import {
  Route,
  useHistory,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import { toast, ToastContainer } from "react-toastify";
import { IPublicClientApplication } from "@azure/msal-browser";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { CustomNavigationClient } from "./Utils/NavigationClient";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UpcomingIcon from "@mui/icons-material/Upcoming";

import HistoryIcon from "@mui/icons-material/History";
import { loginRequest } from "./authConfig";
import { loginAzure } from "./Services/AuthService/AuthService";
import { userLogin } from "./Redux/User/UserAction";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AedasLoading from "./Components/AedasLoading/AedasLoading";
import Header from "./Components/Header/Header";
import { Home } from "@mui/icons-material";
import UpcomingClosures from "./Pages/UpcomingClosures/UpcomingClosures";
import NewClosure from "./Pages/NewClosure/NewClosure";
import HistoryClosures from "./Pages/HistoryClosures/HistoryClosures";
import Users from "./Pages/Users/User";
import { usePermission } from "./zustand/usePermission";
import { getClosures } from "./Services/ClosureService/ClosureService";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const setPermission = usePermission((state: any) => state.setPermission);

  const history = useHistory();
  const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.userReducer.user?.user);

  useEffect(() => {
    if (isAuthenticated && inProgress === "none") {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          const responseBackend: any = await loginAzure(
            response.accessToken
          ).catch((error) => console.log("ERROR"));

          let position: string = "Por defecto";
          console.log(responseBackend.data.token);
          sessionStorage.setItem(
            "aedas_user_token",
            responseBackend.data.token
          );
          if (responseBackend) {
            dispatch(
              userLogin(responseBackend.data, response.accessToken, position)
            );
            setIsLoading(false);
          } else {
            toast.error("No tienes permiso");
            history.push("/");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          toast.error("No tienes permiso");
        });
    } else if (!isAuthenticated && inProgress === "none") {
      instance
        .loginRedirect(loginRequest)
        .then((response) => {
          console.log("login", response);
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (inProgress === "none") {
      setIsLoading(false);
    }
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    getClosures(user?.email).then((response: any) => {
      setPermission(response.data[1]?.permission);
    });
  }, []);

  const menu: any = [
    {
      name: "Próximos cierres",
      localPath: "/",
      icon: <UpcomingIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Próximos",
      hasPermissions: true,
    },
    {
      name: "Historial cierres",
      localPath: "/historial",
      icon: <HistoryIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Historial",
      hasPermissions: true,
    },
    {
      name: "Nuevo cierre",
      localPath: "/nuevo",
      icon: <AddCircleOutlineIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Nuevo",
      hasPermissions: true,
    },
    {
      name: "Usuarios",
      localPath: "/usuarios",
      icon: <PeopleAltIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Usuarios",
      hasPermissions: true,
    },
    // {
    //   name: "Documentos",
    //   localPath: "/documentos",
    //   icon: <ArticleIcon />,
    //   onClick: history.push,
    //   canView: true,
    //   shortName: "Doc.",
    //   hasPermissions: true,
    // },
    // {
    //   name: "Editar sociedad",
    //   localPath: "/editar-sociedad",
    //   icon: <BorderColorIcon />,
    //   onClick: history.push,
    //   canView: true,
    //   shortName: "Edit. Soc.",
    //   hasPermissions: true,
    // },
    // {
    //   name: "Gestion de usuarios",
    //   localPath: "/usuarios",
    //   icon: <GroupsIcon />,
    //   onClick: history.push,
    //   canView: true,
    //   shortName: "Usuarios",
    //   hasPermissions: true,
    // },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }
  return (
    <>
      <div
        style={{ height: "100vh", overflowX: "hidden" }}
        className="app-container"
      >
        {isLogin ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          className="w-100"
          style={{
            flex: 1,
            marginLeft: "70px",
            maxWidth: "calc(100vw - 70px)",
            overflow: "hidden",
          }}
        >
          <Switch>
            <PrivateRoute exact path="/" auth={isLogin} hasPermissions={true}>
              <UpcomingClosures />
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/historial"
            >
              <HistoryClosures />
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/nuevo"
            >
              <NewClosure />
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/usuarios"
            >
              <Users />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </>
  );
};

const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to={"/login"}></Redirect>;
    }
  } else {
    return <Redirect to={"/login"}></Redirect>;
  }
};
