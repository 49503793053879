//@ts-ignore
import { useState } from "react";
import * as yup from "yup";

import NewClosureForm from "../../Components/NewClosureForm/NewClosureForm";

function NewClosure(props: any) {
  const [value, setValue] = useState<any>(null);
  console.log("vvv", value);
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            fontFamily: "lis-title",
            fontWeight: "bold",
            paddingTop: "20px",
          }}
        >
          Nuevo cierre
        </h1>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            height: "100%",
            padding: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "95%",
              padding: "1rem",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <NewClosureForm setValue={setValue}></NewClosureForm>
            </div>
          </div>
          <div
            style={{
              height: "100%",
              width: "95%",
              padding: "1rem",
              background: "white",
              borderRadius: "10px",
              justifyContent: "center",
            }}
          >
            <div>
              {value === null ? (
                <></>
              ) : (
                <>
                  {value?.id === 1 ? (
                    <>
                      {" "}
                      <h1
                        style={{
                          fontSize: "30px",
                          textAlign: "center",
                          padding: "1rem",
                        }}
                      >
                        Anaplan
                      </h1>
                      <ul>
                        <li>
                          <strong>
                            Informe de Situaci&oacute;n Comercial + Anticipos
                            Clientes:
                          </strong>
                        </li>
                      </ul>
                      <p style={{ marginLeft: "80px" }}>
                        Se cierran los datos del diario de transacciones para
                        situaci&oacute;n comercial y las tablas que componen los
                        anticipos de clientes.
                      </p>
                      <p style={{ marginLeft: "80px" }}>
                        <strong>Ficheros que se generan: </strong>
                        1.19.DAT_RitmoVentasReal, 1.18.DAT_RitmoVentas_EE,
                        1.26.DAT_VentasCP, 1.27.DAT_VentasCP_EE,
                        1.20.DAT_EntregasReales, 1.21.DAT_EntregasReales_EE.
                      </p>
                      <ul>
                        <li>
                          <strong>Seguimiento Presupuestario + Hitos:</strong>
                        </li>
                      </ul>
                      <p style={{ marginLeft: "80px" }}>
                        Se cierran los datos relacionados con el presupuesto:
                        Partidas presupuestarias, presupuesto actual y
                        ejecutado. Se cierran las fechas UPO de cumplimiento de
                        hitos de cada promoci&oacute;n.
                      </p>
                      <p style={{ marginLeft: "80px" }}>
                        <strong>Ficheros que se generan:</strong>{" "}
                        2.3.M_PartidasGasto, 5.2.M_PartidasGasto,
                        5.3.M_PartidasGasto, 3.3.M_PartidasIngreso,
                        6.2.M_PartidasIngreso, 6.3.M_PartidasIngreso,
                        2.1.M_Apartado Gasto, &nbsp;3.1.M_Apartado Ingreso,
                        2.2.M_Capitulo Gasto, 5.1.M_Capitulo Gasto,
                        3.2.M_Capitulo Ingreso, 6.1.M_Capitulo Ingreso,
                        1.5.DAT_InputRealMensualizadoGasto,
                        &nbsp;1.8.DAT_InputRealMensualizadoIngreso,
                        1.4.DAT_InputRealAcumuladoGasto,
                        1.7.DAT_InputRealAcumuladoIngreso,
                        1.3.DAT_InputPresupuestoGasto,
                        1.6.DAT_InputPresupuestoIngreso, 1.2.DAT_HitosReal,
                        1.1.DAT_HitosEE, 1.11.DAT_Unidades,
                        1.10.DAT_Unidades_EE, 1.16.DAT_PrecioUnitario,
                        1.14.DAT_PrecioUnitario_EE, 1.13.DAT_IngresosTotales,
                        1.12.DAT_IngresosTotales_EE.
                      </p>
                      <ul>
                        <li>
                          <strong>
                            Curvas de Obra y L&iacute;mites Pr&eacute;stamo:
                          </strong>
                        </li>
                      </ul>
                      <p style={{ marginLeft: "80px" }}>
                        Se cierran los datos de curvas de obra de promociona y
                        el limite PH, Riesgo Dispuesto v&iacute;a
                        Disposici&oacute;n CEC REAL y Saldo Cr&eacute;dito
                        Promotor-Neto Real.
                      </p>
                      <p style={{ marginLeft: "80px" }}>
                        <strong>Ficheros que se generan:</strong>{" "}
                        1.9.DAT_RitmodeObraEnConstruccion,
                        1.22.DAT_SaldoFinalCajaRestringida,
                        1.24.DAT_PrestamoPromotor, 1.25.DAT_PrestamoPromotor_EE,
                        Saldo Cr&eacute;dito Promotor-Neto Real.
                      </p>
                      <p>&nbsp;</p>
                    </>
                  ) : (
                    <>
                      <h1
                        style={{
                          fontSize: "30px",
                          textAlign: "center",
                          padding: "1rem",
                        }}
                      >
                        Power BI: Informe mensual
                      </h1>
                      <ul>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Ventas-Entregas:
                          </span>{" "}
                          Se guardan los datos relativos a Situaci&oacute;n
                          Comercial, tanto para BTS como BTR, as&iacute; como
                          KPIs relativos a los partes de Ventas y Entregas. Las
                          slides involucradas del informe Mensual de Control de
                          Gesti&oacute;n son las siguientes:
                          <ul>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Resumen Ejecutivo&nbsp;
                              </span>
                              <ul>
                                <li>Actividad Comercial (BTS+BTR),&nbsp;</li>
                                <li>Coberturas de Targets de Entregas BP</li>
                              </ul>
                            </li>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Negocio BTS - Actividad Comercial
                              </span>
                              <ul>
                                <li>
                                  Situaci&oacute;n Comercial (II), (III), (IV),
                                  (V)
                                </li>
                                <li>Ventas BTS (I), (II), (III), (IV)</li>
                                <li>Entregas BTS (I), (II), (III)</li>
                              </ul>
                            </li>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Negocio BTR - Actividad Comercial
                              </span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Hitos:</span> Se
                          guardan los datos relativos a Hitos y Active Units,
                          tanto para BTS como BTR. Las slides involucradas del
                          informe Mensual de Control de Gesti&oacute;n son las
                          siguientes:
                          <ul>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Hitos: Resumen Ejecutivo&nbsp;
                              </span>
                              <ul>
                                <li>KPIs Operaciones (BTR + BTS)</li>
                              </ul>
                            </li>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Negocio BTS - Actividad Comercial
                              </span>
                              <ul>
                                <li>Situaci&oacute;n Comercial (I)</li>
                              </ul>
                            </li>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Negocio BTS - Operaciones
                              </span>
                              <ul>
                                <li>Operaciones BTS (I), (II), (III)</li>
                                <li>
                                  Slides relativas a hitos: Puesta en Carga,
                                  Licencias de Obra, Lanzamiento Comercial,
                                  Inicios Construcci&oacute;n
                                </li>
                              </ul>
                            </li>
                            <li>
                              <span style={{ fontWeight: "bold" }}>
                                Negocio BTR&nbsp;{" "}
                              </span>
                              <ul>
                                <li>BTR - Operaciones</li>
                                <li>Inicios construcci&oacute;n BTR</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </>
                  )}{" "}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewClosure;
