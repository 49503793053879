import { create } from "zustand";

export const usePermission = create((set) => ({
  permission: null,
  setPermission: (permission_auth: any) => {
    set(() => ({
      permission: permission_auth,
    }));
  },
}));
