import moment from "moment";

export const compareDateWithHour = (a: any, b: any) => {
  // Calcular la diferencia entre las fechas
  if (a === "-") {
    return -1;
  }
  if (b === "-") {
    return 1;
  }
  const diff = moment(a, "DD-MM-YYYY HH:mm").diff(
    moment(b, "DD-MM-YYYY HH:mm"),
    "hours"
  );

  // Devolver -1 si a es menor que b, 1 si es mayor, o 0 si son iguales
  return diff < 0 ? -1 : diff > 0 ? 1 : 0;
};

export const compareDate = (a: any, b: any) => {
  // Calcular la diferencia entre las fechas
  const diff = moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"), "days");

  // Devolver -1 si a es menor que b, 1 si es mayor, o 0 si son iguales
  return diff < 0 ? -1 : diff > 0 ? 1 : 0;
};
