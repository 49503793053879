import axios from "axios";

export const getAllUsers = () => {
  return axios.get(process.env.REACT_APP_API_BACKEND + "/user/", getHeaders());
};

export const addUser = (email: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/user/alta?email=" + email,
    {},
    getHeaders()
  );
};

export const getAllUsersEmails = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/autocomplete/allEmployeeEmails",
    getHeaders()
  );
};

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("aedas_user_token")}`,
    },
  };
};
