import { useEffect, useState } from "react";
import MainListItems from "../LisHeaderProvaider/listItems";

import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
// import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
// import { getUserPhoto } from '../../../service/AuthService/AuthService';

import "./Header.css";
import { userLogout } from "../../Redux/User/UserAction";

function Header(props: any) {
  const { menu, setIsOpen, open, setisLogin } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpenClose = () => {
    setIsOpen(!open);
  };
  const { instance, accounts, inProgress } = useMsal();

  const user = useSelector((state: any) => state.userReducer.user);
  // const position = useSelector((state: any) => state.userReducer.position);

  console.log("user", user);
  const [userAvatar, setUserAvatar] = useState<any>();

  // useEffect(() => {

  //   getUserPhoto(user?.employee.id_azure)
  //     .then((response: any) => {
  //         setUserAvatar(response.data)
  //     })

  // }, [user])

  useEffect(() => {
    const imageTag = document.querySelector("#user-avatar-open");
    const imageTagClose = document.querySelector("#user-avatar-close");

    if (userAvatar != null) {
      const imageSrc = URL.createObjectURL(userAvatar);
      imageTag?.setAttribute("src", imageSrc);
      imageTagClose?.setAttribute("src", imageSrc);
    } else {
      imageTag?.setAttribute("src", "/Assets/img/blank.svg");
      imageTagClose?.setAttribute("src", "/Assets/img/blank.svg");
    }
  }, [userAvatar, open]);

  const handleLogOut = () => {
    instance
      .logoutRedirect()
      .then(() => {
        localStorage.removeItem("aedas_user");
        dispatch(userLogout());
      })
      .catch(() => {
        localStorage.removeItem("aedas_user");
        dispatch(userLogout());
      })
      .catch(() => {});
  };

  return (
    <div
      style={{
        width: open ? "300px" : "70px",
        zIndex: 10,
        height: "100%",
        backgroundColor: "white",
        position: "fixed",
        overflow: "hidden",
        boxShadow: "rgb(216 216 216 / 40%) 1px 1px 10px",
      }}
      className="header-container"
    >
      <div className="logo-container">
        {open ? (
          <>
            <img
              src="/Assets/img/LogoAedasSmall.svg"
              style={{ height: "70px", cursor: "pointer" }}
              onClick={() => history.push("/")}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1 className="appName">Cierres Data</h1>
            </div>
          </>
        ) : (
          <img
            src="/Assets/img/LogoAedasSmall.svg"
            style={{ height: "70px", textAlign: "center", cursor: "pointer" }}
            onClick={() => history.push("/")}
          ></img>
        )}
      </div>
      <div className="openCloseButton_container">
        <IconButton onClick={handleOpenClose} className="openCloseButton">
          {open ? (
            <ArrowBackIosIcon></ArrowBackIosIcon>
          ) : (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          )}
        </IconButton>
      </div>

      <MainListItems
        setOpen={setIsOpen}
        open={open}
        menu={menu}
      ></MainListItems>
      {open ? (
        <div className="personHeader">
          <hr style={{ margin: "20px" }}></hr>

          <div className=" p-2 col-2 w-100 d-flex justify-content-between ">
            <div className="row w-100">
              <div
                className="d-flex aling-items-center justify-content-center"
                style={{ gap: "1rem" }}
              >
                <img className="imgPersonHeader" id="user-avatar-open"></img>

                <div className="row d-flex align-items-center">
                  <div>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontFamily: "var(--lis-title)",
                      }}
                    >
                      {user?.user?.display_name ?? "Employee name"}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="personHeader ">
          <div className="row">
            <div className="d-flex flex-column align-items-center">
              <img
                className="imgPersonHeaderSmall"
                id="user-avatar-close"
              ></img>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
