import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import { useFetch } from "../../Hooks/useFetch";
import {
  getClosures,
  getHistory,
} from "../../Services/ClosureService/ClosureService";
import { compareDate, compareDateWithHour } from "../../Utils/dateUtils";
import { useSelector } from "react-redux";
const stateFunc = (params: GridRenderCellParams) => {
  if (params.row["Fecha Ejecucion Real"] && params.row["Fecha Completado"]) {
    return (
      <span
        style={{
          background: "var(--aedas-green)",
          color: "white",
          padding: "3px",
          borderRadius: "10px",
        }}
      >
        Ejecución finalizada
      </span>
    );
  }
  if (
    (moment()
      .add("hour", 8)
      .isAfter(moment(params.row["Fecha Ejecucion Real"])) &&
      params.row["Fecha Completado"] === null) ||
    params.row["Fecha Completado"] === undefined
  ) {
    return (
      <span
        style={{
          background: "var(--aedas-red)",
          color: "white",
          padding: "3px",
          borderRadius: "10px",
        }}
      >
        Error
      </span>
    );
  } else {
    return (
      <span
        style={{
          background: "var(--secondary-color)",
          color: "white",
          padding: "3px",
          borderRadius: "10px",
        }}
      >
        En ejecución
      </span>
    );
  }
};

const columns = [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "FechaCierre",
    headerName: "Mes cierre",
    sortable: false,
    width: 200,
  },
  {
    field: "status",
    headerName: "Estado",
    width: 200,
    renderCell: stateFunc,
  },
  {
    field: "Fecha Ejecucion",
    headerName: "Fecha planificada",
    width: 200,
    sortComparator: compareDate,
    valueGetter: (params: any) => {
      return `${moment(params.value).format("DD-MM-YYYY")}`;
    },
  },
  { field: "copia_prinex", headerName: "Hora cierre", width: 200 },
  {
    field: "Fecha Ejecucion Real",
    headerName: "Fecha ejecución real",
    sortComparator: compareDateWithHour,
    width: 300,
    valueGetter: (params: any) => {
      return `${moment(params.value).format("DD-MM-YYYY HH:mm")}`;
    },
  },
  {
    field: "Fecha Completado",
    headerName: "Fecha finalización",
    width: 300,
    sortComparator: compareDateWithHour,
    valueGetter: (params: any) => {
      if (params.value === null) {
        return `-`;
      }
      return `${moment(params.value).format("DD-MM-YYYY HH:mm")}`;
    },
  },
  {
    field: "Departamento",
    headerName: "Aplicación",
    sortable: false,
    width: 200,
    valueGetter: (params: any) => {
      if (params.value === "CG") {
        return `Control de Gestión`;
      }
      return `${params.value}`;
    },
  },
  {
    field: "Tipo",
    headerName: "Módulo",
    sortable: false,
    width: 200,
  },
  {
    field: "FechaModificado",
    headerName: "Fecha creación/modificación",
    sortComparator: compareDateWithHour,
    width: 300,
    valueGetter: (params: any) => {
      return `${moment(params.value).format("DD-MM-YYYY HH:mm")}`;
    },
  },
  {
    field: "creador",
    headerName: "Creador",
    sortable: false,
    width: 200,
  },
  {
    field: "Modificado por",
    headerName: "Modificado",
    sortable: false,
    width: 200,
    valueGetter: (params: any) => {
      if (params.value === null) {
        return "No ha sido modificado";
      } else {
        return params.value;
      }
    },
  },
];

function HistoryClosures(props: any) {
  const user = useSelector((state: any) => state.userReducer.user?.user);

  const [closures, isFetchingClousures] = useFetch(getHistory, user?.email);

  const data = [
    {
      id: 1,
      "Fecha Ejecucion": "2022-10-07",
      copia_prinex: "23:00",
      "Fecha Ejecucion Real": null,
      "Fecha Completado": null,
      "Modificado por": "Ayoub El Harkaoui",
      FechaModificado: "2022-11-22T14:24:43.100000",
      Sobrescrito: 0,
    },
  ];
  console.log("ccc", closures, isFetchingClousures);

  if (isFetchingClousures) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 style={{ fontFamily: "lis-title", fontWeight: "bold" }}>
        Historial de cierres
      </h1>
      <div
        style={{
          height: "80%",
          width: "95%",
          padding: "1rem",
          background: "white",
          borderRadius: "10px",
        }}
      >
        <DataGrid
          style={{ height: "100%" }}
          rows={
            closures?.fechas
              ? closures?.fechas.map((value: any, i: number) => {
                  return { ...value, id: i };
                })
              : []
          }
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}
export default HistoryClosures;
